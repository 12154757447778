/* Títulos e imágenes del home */

#home {
  display: flex;
  justify-content: space-around;
}

.info-home {
  text-align: left;
  position: relative;
}

.tittle-home > h4 {
  font-family: "Roboto", sans-serif;
  font-family: "Roboto Mono", monospace;
  font-weight: bolder;
}

#home img {
  width: 12rem;
  margin-top: 2rem;
}

/* .image-left {
  margin-top: -8vh;
  width: 7.5rem;
  height: 6rem;
} */

/* .image-middle {
  margin-top: 8rem;
  margin-left: 4rem;
  width: 8rem;
  height: 2rem;
} */

/* .image-right {
  float: right;
  width: 3rem;
  height: 15rem;
} */

.tittle-home {
  position: absolute;
  background-color: #131747;
  color: white;
  height: 7rem;
  width: 25rem;
  text-align: right;
  padding-right: 30px;
  transition: all 0.5s;
}

.tittle-home:hover {
  transform: scale(1.1);
}

.left-top {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fe9829;
  margin-top: 4rem;
  margin-left: 25rem;
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12rem;
  margin-left: 4rem;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
  margin-left: 15rem;
}

.icons-container {
  width: 24rem;
}

/* .image-middle {
  margin-top: 15vh;
  margin-left: 12vw;
  width: 33vw;
  height: 50vh;
}

.image-right {
  width: 27vw;
  height: 52vh;
} */

.tittle-home {
  height: 15vh;
  width: 25vw;
  padding-right: 30px;
}

.left-top {
  margin-top: 7.5vh;
  margin-left: 20vw;
}

.left {
  margin-top: 45vh;
  margin-left: 7vw;
}

.right {
  margin-top: 45vh;
  margin-left: 67.22vw;
}

#home p {
  margin-top: 5rem;
  font-size: 1.1rem;
  width: 20rem;
}

@media screen and (min-width: 600px) {
  /* .image-left {
        width: 20vw;
        height: 45vh;
    }
    
    .image-middle {
        margin-top: 15vh;
        margin-left: 12vw;
        width: 33vw;
        height: 50vh;
    }

    .image-right {
        width: 27vw;
        height: 52vh;
    }

    .tittle-home {
        height: 15vh;
        width: 25vw;
        padding-right: 30px;
    }

    .left-top {
        margin-top: 7.5vh;
        margin-left: 20vw;
    }

    .left {
        margin-top: 45vh;
        margin-left: 7vw;
    }

    .right {
        margin-top: 45vh;
        margin-left: 67.22vw;
    } */
}

/* MEDIA QUERIES FOR MOBILE IN HORIZONTAL DISPLAY */
/* TODO:Optimize this */

/* @media screen and (min-width: 450px) and (max-height: 550px) {
    .icons-container {
        width: 50vw;
    }

    .image-left {
        width: 20vw;
        height: 45vh;
    }
    
    .image-middle {
        margin-top: 15vh;
        margin-left: 12vw;
        width: 33vw;
        height: 50vh;
    }

    .image-right {
        width: 27vw;
        height: 52vh;
    }

    .tittle-home {
        height: 15vh;
        width: 25vw;
        padding-right: 30px;
    }

    .left-top {
        margin-top: 7.5vh;
        margin-left: 20vw;
    }

    .left {
        margin-top: 45vh;
        margin-left: 7vw;
    }

    .right {
        margin-top: 45vh;
        margin-left: 67.22vw;
    }
} */
