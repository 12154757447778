.App {
  text-align: center;
}

body {
  font-size: 1vw;
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #919191;
}
