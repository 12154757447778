:root {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
}

.vl {
  border-left: 1.5rem solid #dd780a;
  height: 100%;
  position: absolute;
  left: 75vw;
  z-index: -1;
}

@media screen and (min-width: 360px) {
  :root {
    font-size: 10px;
  }

  .vl {
    left: 58vw;
  }
}

@media screen and (min-width: 600px) {
  :root {
    font-size: 16px;
  }

  .vl {
    left: 58vw;
  }
}

@media screen and (min-width: 900px) {
  :root {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  :root {
    font-size: 24px;
  }
}

@media screen and (min-width: 1536px) {
  :root {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-size: 28px;
  }
}
