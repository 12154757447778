.tittle-container {
  background-color: #fe9829;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 60px;
}

.tittle-page {
  display: flex;
  align-items: center;
  height: 7vh;
}

.logo-page {
  width: 50px;
  height: 50px;
}

.container-text-page {
  background-color: #f1efed;
}

.text-page {
  margin-top: 50px;
  font-size: 260%;
  font-weight: 600;
}

.images-template-container {
  position: relative;
  height: 15vh;
}

.image-page-back {
  position: absolute;
  height: 15vh;
  width: 20vw;
  transition: all ease-in-out 0.1s;
  border: solid 10px  rgb(76 63 63); 
}

.image-page-front {
  position: absolute;
  height: 15vh;
  width: 20vw;
  border: solid 10px  rgb(76 63 63); 
}

.field-image-container:hover .image-page-front {
  visibility: hidden;
}

.btn-pages {
  margin: 0;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@media screen and (min-width: 830px) {
  .tittle-page {
  }

  .images-template-container {
    height: 35vh;
  }

  .image-page-back {
    height: 35vh;
    width: 22vw;
  }

  .image-page-front {
    height: 35vh;
    width: 22vw;
  }

  .logo-page {
    width: 80px;
    height: 80px;
  }

  .text-page {
    margin-top: 50px;
    font-size: 1.19rem;
    font-weight: 600;
    margin-right: 12rem;
    margin-left: 12rem;
  }
}

.text-page h2 {
  font-size: 1.35rem;
  font-weight: bold;
}

/* MEDIA QUERIES FOR MOBILE IN HORIZONTAL DISPLAY */
/* TODO:Optimize this */

@media screen and (min-width: 450px) and (max-height: 550px) {
  .tittle-page {
    height: 15vh;
  }

  .images-template-container {
    height: 35vh;
  }

  .image-page-back {
    height: 35vh;
    width: 22vw;
  }

  .image-page-front {
    height: 35vh;
    width: 22vw;
  }

  .logo-page {
    width: 80px;
    height: 80px;
  }

  .text-page {
    margin-top: 50px;
    font-size: 150%;
    font-weight: 600;
  }
}
