.historia-wrapper {
  margin-top: 65px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.p-historia-container {
  display: flex;
  align-items: center;
  padding: 0px 35px;
  width: 55vw;
  margin-left: 10vw;
  height: 43vh;

  box-shadow: 0 0 5px 4px #555;
  background-color: #131747d1;
}

.image-historia {
  display: none;
  width: 18vw;
  height: 36vw;
}

.historia-section p {
  color: rgb(255, 255, 255);
  font-size: 200%;
}

@media screen and (max-width: 450px) {
  .historia-section p {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 830px) {
  .historia-wrapper {
    display: flex;
    flex-direction: row;
  }

  .image-historia {
    display: initial;
  }

  .historia-section p {
    font-size: 0.9rem;
  }

  .p-historia-container {
    width: 55vw;
    margin-left: 10vw;
    height: 60vh;
  }
}

/* MEDIA QUERIES FOR MOBILE IN HORIZONTAL DISPLAY */
/* TODO:Optimize this */

@media screen and (min-width: 450px) and (max-height: 550px) {
  .historia-wrapper {
    display: flex;
    flex-direction: row;
  }

  .image-historia {
    display: initial;
  }

  .historia-section p {
    font-size: 200%;
  }

  .p-historia-container {
    width: 55vw;
    margin-left: 10vw;
    height: 60vh;
  }
}
