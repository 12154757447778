.carousel {
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 80vw;
  margin-left: 10vw;
}

.carousel-inner {
  white-space: nowrap;
  transition: transform 0.5s;
}

#carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#carousel-item img {
  height: 36rem;
  /* width: 86rem; */
  width: calc(80vw);
}

#carousel-item h3 {
  position: absolute;
  top: 70%;
  color: #ffffff;
  font-weight: bolder;
}

#carousel-item h5 {
  position: absolute;
  top: 83%;
  color: #ffffff;
  font-weight: bolder;
}

.btn-carousel {
  background-color: transparent;
  border-color: transparent;
  position: absolute;
  top: 45%;
}

.back {
  left: 2%;
}

.forward {
  right: 2%;
}

.icon-carousel {
  height: 0.5rem;
  width: 0.7rem;
}

@media screen and (min-width: 830px) {
  #carousel-item img {
    height: 22rem;
  }
}

/* MEDIA QUERIES FOR MOBILE IN HORIZONTAL DISPLAY */
/* TODO:Optimize this */

@media screen and (min-width: 450px) and (max-height: 550px) {
  /* #carousel-item img {
    height: 50rem;
  } */
}
