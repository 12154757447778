.grupo-container {
  display: flex;
  justify-content: space-around;
  margin-top: 65px;
  flex-direction: column;
}

.divs-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-family: GroupFont, sans-serif;
  height: 20vh;
  padding: 0 10px;
  color: white;
  margin-bottom: 65px;
}

/* #131747; */

.groups {
  display: flex;
  flex-direction: column-reverse;
  width: 8rem;
  background-color: #131747;
}

.paragraph {
  font-size: 180%;
}

h3 {
  font-size: 1.1rem;
}

.pos4-grupo > img {
  height: 11rem;
  width: 10rem;
}

@media screen and (min-width: 750px) and (min-height: 551px) {
  .grupo-container {
    flex-direction: initial;
  }

  .divs-container {
    height: 65vh;
    width: 50vw;
  }

  .divs-container {
    height: 20rem;
  }

  .divs-container > div {
    width: 10rem;
  }

  .groups p {
    margin-bottom: 1rem;
    font-size: 0.6rem;
  }
}

/* MEDIA QUERIES FOR MOBILE IN HORIZONTAL DISPLAY */
/* TODO:Optimize this */

@media screen and (max-width: 450px) {
  .groups {
    height: 21rem;
    width: 10rem;
  }

  h3 {
    font-size: 0.85rem;
  }

  .tittle-section {
    font-size: 1rem;
  }

  .paragraph {
    font-size: 0.85rem;
  }
}

@media screen and (min-width: 450px) and (max-height: 550px) {
  .grupo-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .groups h3 {
    margin-top: 75%;
  }

  .divs-container {
    justify-content: space-around;
    height: 65vh;
    width: 100vw;
  }

  .groups h3 {
    margin-top: 75%;
  }

  .divs-container {
    justify-content: space-around;
    height: 65vh;
    width: 100vw;
  }

  .groups p {
    margin-bottom: 10vh;
    font-size: 115%;
  }
}
