.padding-0 {
  padding: 0;
}

.margin-0 {
  margin: 0 0;
}

.tittle-section {
  margin-top: 65px;
  background-color: #131747;
  color: white;
  margin-bottom: 0px;
  width: 40vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
}

.tittle-section:before {
  content: "";
  width: 0;
  position: absolute;
  top: 0;
  right: 0;
  border-top: 5vh solid #919191;
  border-left: 2.5vh solid #131747;
}

.height {
  height: 5vh;
}

@media screen and (min-width: 830px) {
  .tittle-section:before {
    border-top: 12vh solid #919191;
    border-left: 8vh solid #131747;
  }

  .height {
    height: 12vh;
  }
}

/* MEDIA QUERIES FOR MOBILE IN HORIZONTAL DISPLAY */
/* TODO:Optimize this */

@media screen and (min-width: 450px) and (max-height: 550px) {
  .tittle-section:before {
    border-top: 12vh #919191;
    border-left: 8vh solid #131747;
  }

  .height {
    height: 12vh;
  }
}
