/* Basic Config Styles */

ul {
  list-style-type: none;
}

/* End Basic Config Styles */

#title-servicios {
  margin-top: 0;
}

.section-servicios {
  display: grid;
  column-gap: 0px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(24vw, 1fr));
  margin-top: 8%;
  width: 100vw;
  cursor: crosshair;
}

.image-container {
  margin: 0;
}

.image-servicios {
  border-radius: 0.3vw;
  box-shadow: 0 4px 6px 0 rgba(34, 60, 80, 0.16);
  transition: all ease-in-out 0.2s;
  width: 25vw;
  height: 18vh;
}

.text-servicios-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #131747;
  position: static;
  margin-left: 15vw;
  margin-top: 25px;
  border-radius: 10px 25px 40px 70px;
  width: 75vw;
  height: 25vh;
}

.p-text {
  text-align: center;
  color: white;
  width: 60vw;
  font-size: 260%;
}

.image-container-wrapper {
  position: relative;
  margin: 0;
  justify-self: center;
}

.image-container-wrapper:hover > h4 {
  visibility: visible;
}

.image-container-wrapper:hover .image-servicios {
  filter: brightness(0.35);
  box-shadow: 0 0.8em 12px 0 rgba(34, 60, 80, 0.16);
}

.image-container-wrapper > h4 {
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 2;
  visibility: hidden;
  transition: all ease-in-out 0.1s;
}

#section-servicios {
  margin-bottom: 7%;
}

@media screen and (min-width: 830px) and (min-height: 551px) {
  .image-container-wrapper > h4 {
    font-size: 0.8rem;
  }

  .text-servicios-container {
    justify-content: flex-end;
    position: absolute;
    border-radius: 0px;
    padding: 15px;
    margin-top: 0px;
    z-index: -2;
    margin-left: 39vw;
    width: 60vw;
    height: 60vh;
  }

  .p-text {
    right: 30px;
    width: 60vw;
    font-size: 180%;
    width: 30vw;
  }

  .section-servicios {
    width: 55vw;
    grid-template-columns: repeat(auto-fill, minmax(13vw, 1fr));
  }

  .image-servicios {
    width: 16vw;
    height: 30vh;
  }

  .grid-text-container {
    position: relative;
  }
}

/* MEDIA QUERIES FOR MOBILE IN HORIZONTAL DISPLAY */
/* TODO:Optimize this */

@media screen and (min-width: 450px) and (max-height: 550px) {
  .image-container-wrapper > h4 {
    font-size: 0.8rem;
  }

  .text-servicios-container {
    height: 45vh;
  }

  .p-text {
    right: 30px;
    width: 65vw;
    font-size: 240%;
  }

  .section-servicios {
    grid-template-columns: repeat(auto-fill, minmax(24vw, 1fr));
  }

  .image-servicios {
    width: 25vw;
    height: 30vh;
  }
}
