.tittle-construya {
  background-color: #131747;
  color: white;
  margin-top: 65px;
  width: 100%;
  margin-bottom: 4rem;
}

.construya {
  display: flex;
  flex-direction: column;
}

.text-construya {
  width: 90vw;
  margin-left: 5vw;
  background-color: #131747d1;
  color: white;
}

.form-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
}

.form {
  width: 26rem;
  display: flex;
  flex-direction: column;
}

.input-form {
  border: 2px solid black;
  border-radius: 2px;
  height: 1.5rem;
}

.construya-logo {
  margin-top: 65px;
  width: 6rem;
  height: 6rem;
}

.button-form {
  color: white;
  background-color: #131747;
  margin-top: 10px;
  align-self: center;
  font-size: 0.8rem;
  width: 8rem;
  height: 4rem;
  border: 1px solid white;
  border-radius: 10px;
}

.textarea {
  width: 26rem;
  height: 5rem;
}

@media screen and (max-width: 1100px) {
  .button-form {
    font-size: 1rem;
    width: 10rem;
    height: 4rem;
  }
}
