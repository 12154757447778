/*Codigo CSS de la services bar*/

.services-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #131747;
  height: 3vh;
  font-size: 190%;
  font-weight: 400;
  width: 100%;
}

.services-bar a {
  color: white;
}

@media screen and (min-width: 830px) {
  .services-bar {
    font-size: 125%;
    height: 6vh;
  }

  .services-bar a {
    cursor: pointer;
  }
}

/* MEDIA QUERIES FOR MOBILE IN HORIZONTAL DISPLAY */
/* TODO:Optimize this */

@media screen and (min-width: 450px) and (max-height: 550px) {
  .services-bar {
    font-size: 125%;
    height: 6vh;
  }

  .services-bar a {
    cursor: pointer;
  }
}
