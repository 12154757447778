.contenedor-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 9rem;
  background-color: #919191;
}

.contenedor-header a {
  font-size: 1.075rem;
  color: white;
  margin-right: 1rem;
  margin-left: 1rem;
}

.icons-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* .logo-header {
    position: absolute;
    width: 7vw;
    height: 3vh;
    top: 7vh;
} */

.logo {
  margin-left: 0rem;
  width: 14rem;
  margin-bottom: 0.8rem;
  height: 6rem;
  align-self: center;
}
.btn-header focus {
  outline: none;
}

@media screen and (min-width: 830px) {
}

/* MEDIA QUERIES FOR MOBILE IN HORIZONTAL DISPLAY */
/* TODO:Optimize this */

@media screen and (min-width: 450px) and (max-height: 550px) {
}
